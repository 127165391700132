<template>
  <div>
    <h1>Workouts</h1>
    <el-table
      :data="items.filter(item => !search
                                  || item.title.toLowerCase().includes(search.toLowerCase())
                                  || item.user.email.toLowerCase().includes(search.toLowerCase())
                          )"
      v-loading="loading"
      style="width: 100%"
      @sort-change="sortTrigger"
    >
      <el-table-column sortable="custom" label="Title" prop="title" />
      <el-table-column :sortable="false" label="Owner" prop="user.email" />
      <el-table-column sortable="custom" label="Exercises" prop="exercises_count" />
      <el-table-column sortable="custom" label="AVG burn" prop="average_burn_rate" />
      <el-table-column sortable="custom" label="Muscle" prop="main_muscle" />
      <el-table-column sortable="custom" label="Duration" prop="duration" />
      <el-table-column sortable="custom" label="Credit" prop="credit" />
      <el-table-column sortable="custom" align="left" prop="status">
        <template #header>
          Status
        </template>
        <template #default="scope">
          <el-button v-if="scope.row.status === 1" size="mini" type="success" @click="unpublishWorkout(scope.$index, scope.row)">
            Published
          </el-button>
          <el-button v-else size="mini" type="danger" @click="publishWorkout(scope.$index, scope.row)">
            Private
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :sortable="false" align="right">
        <template #header>
          <el-input v-model="search" size="mini" placeholder="Type to search" />
        </template>
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">
            Edit
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <!-- eslint-disable -->
      <el-pagination
        v-model:currentPage="page"
        :page-sizes="[10, 50, 100, 200]"
        :page-size="perPage"
        layout="sizes, prev, pager, next, total"
        :total="total"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @align-items="handlePageChange"
      >
      </el-pagination>
      <!-- eslint-enable -->
    </div>
    <el-dialog v-model="dialogFormVisible" title="Edit workout">
      <template #default>
        <el-form :model="itemBeingEdited">
          <el-form-item label="Name" :label-width="formLabelWidth">
            <el-input v-model="itemBeingEdited.title"></el-input>
          </el-form-item>
          <el-form-item label="Slug" :label-width="formLabelWidth">
            <el-input v-model="itemBeingEdited.slug"></el-input>
          </el-form-item>
          <el-form-item label="Credit" :label-width="formLabelWidth">
            <el-input v-model="itemBeingEdited.credit"></el-input>
          </el-form-item>
          <div style="display:flex;justify-content:center;">
            <div style="display:flex;height:250px;align-items:center;margin: 0 16px;">
              <img
                height="100"
                :src="getImage(itemBeingEdited.image)"
                alt="Workout cover image"
              />
            </div>
            <MuscleBody
              v-bind:abs        ="itemBeingEdited.abs"
              v-bind:forearms   ="itemBeingEdited.forearms"
              v-bind:biceps     ="itemBeingEdited.biceps"
              v-bind:triceps    ="itemBeingEdited.triceps"
              v-bind:upperBack  ="itemBeingEdited.upperBack"
              v-bind:midBack    ="itemBeingEdited.midBack"
              v-bind:lowerBack  ="itemBeingEdited.lowerBack"
              v-bind:hamstrings ="itemBeingEdited.hamstrings"
              v-bind:glutes     ="itemBeingEdited.glutes"
              v-bind:chest      ="itemBeingEdited.chest"
              v-bind:abductors  ="itemBeingEdited.abductors"
              v-bind:adductors  ="itemBeingEdited.adductors"
              v-bind:calves     ="itemBeingEdited.calves"
              v-bind:quadriceps ="itemBeingEdited.quadriceps"
              v-bind:neck       ="itemBeingEdited.neck"
              v-bind:shoulders  ="itemBeingEdited.shoulders"
            />
            <div v-if="itemBeingEdited.status" class="mt-workout-status-holder">
              <span style="background-color: #67c23a;">PUBLIC</span>
            </div>
            <div v-else class="mt-workout-status-holder">
              <span style="background-color: #f56c6c;">PRIVATE</span>
            </div>
          </div>
          <div class="mt-workout-moreinfo-holder">
            <span>Calories: {{ itemBeingEdited.calories }} cal</span>
            <span>AVG Burn rate: {{ itemBeingEdited.average_burn_rate }} cpm</span>
            <span>Category: {{ itemBeingEdited.category_badge }}</span>
            <span>Exercises: {{ itemBeingEdited.exercises_count }}</span>
            <span>Sections: {{ itemBeingEdited.sections_count }}</span>
            <span>Duration: {{ parseWorkoutDuration(itemBeingEdited.duration) }}</span>
            <span>Favorites: {{ itemBeingEdited.favorites }}</span>
            <span>Completes: {{ itemBeingEdited.times_completed }}</span>
            <span>Owner: {{ itemBeingEdited.user.first_name }}
              {{ itemBeingEdited.user.last_name }}
              ({{ itemBeingEdited.user.email }})
            </span>
          </div>
        </el-form>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">
            Cancel
          </el-button>
          <el-button type="primary" @click="dialogFormVisible = false">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDelete" title="Delete">
      <template #default>
        Are you sure you want to delete {{itemBeingEdited.name}}?
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDelete = false">
            Cancel
          </el-button>
          <el-button :disabled="savingInProgress" type="primary" @click="deleteItem()">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import MuscleBody from '@/components/MuscleBody'
import {
  getImage,
  parseMuscleValue,
  fetchWorkouts,
  deleteWorkout,
  publishWorkout,
  parseWorkoutDuration
} from "@/services"

export default {
  name: 'Workouts',
   data() {
    return {
      getImage,
      parseWorkoutDuration,
      parseMuscleValue,
      dialogDelete: false,
      formLabelWidth: 150,
      dialogFormVisible: false,
      perPage: 10,
      sortDirection: false,
      savingInProgress: false,
      page: 1,
      total: 0,
      loading: true,
      column: "created_at",
      order: "descending",
      items: [],
      search: '',
      itemBeingEdited: null
    }
  },
  components: {
    MuscleBody
  },
  mounted() {
    this.getItems()
  },
  methods: {
    sortTrigger(event) {
      this.order = event.order
      this.column = event.prop
      this.getItems()
    },
    async publishWorkout(index, row) {
      let data = {
        uid: row.id,
        publish: 1
      }
      await publishWorkout(data).then(() => {
        this.getItems()
      }).catch(error => {
        console.log('error', error)
      })
    },
    async unpublishWorkout(index, row) {
      let data = {
        uid: row.id,
        publish: 0
      }
      await publishWorkout(data).then(() => {
        this.getItems()
      }).catch(error => {
        console.log('error', error)
      })
    },
    async getItems() {
      this.loading = true
      let data = {
        perPage: this.perPage,
        page: this.page,
        column: this.column,
        order: this.order === "ascending" ? "ASC" : "DESC"
      }
      await fetchWorkouts(data).then(response => {
        this.items = response.data.data.data
        this.total = response.data.data.total
        this.loading = false
      })
    },
    handleEdit(index, row) {
      this.itemBeingEdited = Object.assign({}, row)
      this.dialogFormVisible = true
    },
    handleDelete(index, row) {
      this.itemBeingEdited = Object.assign({}, row)
      this.dialogDelete = true
    },
    async deleteItem() {
      this.savingInProgress = true
      await deleteWorkout(this.itemBeingEdited).then(() => {
        this.savingInProgress = false
        this.dialogDelete = false
        this.getItems()
      }).catch(error => {
        console.log('error', error)
        this.savingInProgress = false
      })
    },
    handleSizeChange($event) {
      this.perPage = $event
      this.getItems()
    },
    handlePageChange() {
      this.getItems()
    }
  },
}
</script>
<style scoped>
.item-breakdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45%;
}
.item-breakdown-title {
  width: 100px;
  padding-right: 5px;
}
.mt-workout-moreinfo-holder {
  display: flex;
  flex-wrap: wrap;
}
.mt-workout-moreinfo-holder > span {
  margin: 2px;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: rgb(228, 228, 228);
}
.mt-workout-status-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
}
.mt-workout-status-holder > span {
  padding: 8px 16px;
  color: white;
  border-radius: 8px;
}
</style>
