<template>
  <div class="sectionPartOutterWrapper flexColumn">
    <div class="my-muscleBodyWrapper">
      <img style="width:100%" alt="Muscle breakdown image - main" src="@/assets/musclesBreakdown/body.png" />
      <img
        class="my-muscleImage"
        v-if="abs"
        alt="Muscle breakdown image - abs"
        :style="`opacity: ${parseMuscleValue(abs)}`"
        src="@/assets/musclesBreakdown/00.png"
      />
      <img
        class="my-muscleImage"
        v-if="forearms"
        alt="Muscle breakdown image - forearms"
        :style="`opacity: ${parseMuscleValue(forearms)}`"
        src="@/assets/musclesBreakdown/01.png"
      />
      <img
        class="my-muscleImage"
        v-if="biceps"
        alt="Muscle breakdown image - biceps"
        :style="`opacity: ${parseMuscleValue(biceps)}`"
        src="@/assets/musclesBreakdown/02.png"
      />
      <img
        class="my-muscleImage"
        v-if="triceps"
        alt="Muscle breakdown image - triceps"
        :style="`opacity: ${parseMuscleValue(triceps)}`"
        src="@/assets/musclesBreakdown/03.png"
      />
      <img
        class="my-muscleImage"
        v-if="upperBack"
        alt="Muscle breakdown image - upperBack"
        :style="`opacity: ${parseMuscleValue(upperBack)}`"
        src="@/assets/musclesBreakdown/04.png"
      />
      <img
        class="my-muscleImage"
        v-if="midBack"
        alt="Muscle breakdown image - midBack"
        :style="`opacity: ${parseMuscleValue(midBack)}`"
        src="@/assets/musclesBreakdown/05.png"
      />
      <img
        class="my-muscleImage"
        v-if="lowerBack"
        alt="Muscle breakdown image - lowerBack"
        :style="`opacity: ${parseMuscleValue(lowerBack)}`"
        src="@/assets/musclesBreakdown/06.png"
      />
      <img
        class="my-muscleImage"
        v-if="hamstrings"
        alt="Muscle breakdown image - hamstrings"
        :style="`opacity: ${parseMuscleValue(hamstrings)}`"
        src="@/assets/musclesBreakdown/07.png"
      />
      <img
        class="my-muscleImage"
        v-if="glutes"
        alt="Muscle breakdown image - glutes"
        :style="`opacity: ${parseMuscleValue(glutes)}`"
        src="@/assets/musclesBreakdown/08.png"
      />
      <img
        class="my-muscleImage"
        v-if="chest"
        alt="Muscle breakdown image - chest"
        :style="`opacity: ${parseMuscleValue(chest)}`"
        src="@/assets/musclesBreakdown/09.png"
      />
      <img
        class="my-muscleImage"
        v-if="abductors"
        alt="Muscle breakdown image - abductors"
        :style="`opacity: ${parseMuscleValue(abductors)}`"
        src="@/assets/musclesBreakdown/10.png"
      />
      <img
        class="my-muscleImage"
        v-if="adductors"
        alt="Muscle breakdown image - adductors"
        :style="`opacity: ${parseMuscleValue(adductors)}`"
        src="@/assets/musclesBreakdown/11.png"
      />
      <img
        class="my-muscleImage"
        v-if="calves"
        alt="Muscle breakdown image - calves"
        :style="`opacity: ${parseMuscleValue(calves)}`"
        src="@/assets/musclesBreakdown/12.png"
      />
      <img
        class="my-muscleImage"
        v-if="quadriceps"
        alt="Muscle breakdown image - quadriceps"
        :style="`opacity: ${parseMuscleValue(quadriceps)}`"
        src="@/assets/musclesBreakdown/13.png"
      />
      <img
        class="my-muscleImage"
        v-if="neck"
        alt="Muscle breakdown image - neck"
        :style="`opacity: ${parseMuscleValue(neck)}`"
        src="@/assets/musclesBreakdown/14.png"
      />
      <img
        class="my-muscleImage"
        v-if="shoulders"
        alt="Muscle breakdown image - shoulders"
        :style="`opacity: ${parseMuscleValue(shoulders)}`"
        src="@/assets/musclesBreakdown/15.png"
      />
    </div>
    <!--  -->
  </div>
</template>

<script>
import { parseMuscleValue, parseThousands } from '@/services'

export default {
  name: 'MuscleBody',
  props: [
    'abs',
    'forearms',
    'biceps',
    'triceps',
    'upperBack',
    'midBack',
    'lowerBack',
    'hamstrings',
    'glutes',
    'chest',
    'abductors',
    'adductors',
    'calves',
    'quadriceps',
    'neck',
    'shoulders'
  ],
  data () {
    return {
      parseMuscleValue: parseMuscleValue,
      parseThousands: parseThousands
    }
  },
}
</script>

<style scoped>
.my-muscleBodyWrapper {
  position: relative;
  max-width: 250px;
  margin: auto;
}
.my-muscleImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .my-muscleBodyWrapper {
    width: unset;
  }
}
</style>